import posthog, { PostHog as PostHogClient } from "posthog-js";

export class PostHog {
  static client: PostHogClient | undefined;

  static init(clientProfileId?: string, gymId?: string) {
    if (!PostHog.client) {
      try {
        PostHog.client = posthog.init(import.meta.env.VITE_PUBLIC_POSTHOG_KEY!, {
          api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
          person_profiles: "always",
          disable_session_recording: true
          // session_recording: {
          //   maskAllInputs: true,
          //   maskTextSelector: ".masked",
          // }
        });
      } catch (e) {
        console.error("Failed to initialize PostHog", e);
      }
    }
    try {
      if (clientProfileId) {
        posthog.identify(clientProfileId, {
          gymId,
          isGym: !!gymId,
        });
      }
    } catch (e) {
      console.error("Failed to identify PostHog", e);
    }
    return PostHog.client;
  }


  static trackEvent(name: string, properties: Record<string, any>) {
    if (!PostHog.client) {
      PostHog.init();
    }
    if (!PostHog.client) return;
    try {
      PostHog.client?.capture(name, properties);
    } catch (e) {
      console.error("Failed to track event", e);
    }
  }
}
