import React, {FC, PropsWithChildren, useContext} from "react";

const DEFAULT_CONFIG = {appName: "viviFIT"};
const ConfigContext = React.createContext(DEFAULT_CONFIG);

export const ConfigProvider:FC<PropsWithChildren> = ({children}) => {
  const [appName, setAppName] = React.useState("viviFIT");
  return (
    <ConfigContext.Provider value={{appName}}>
      {children}
    </ConfigContext.Provider>
  )
}

export const useConfig = () => useContext(ConfigContext);
