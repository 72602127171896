import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PropsWithChildren, useEffect, useMemo } from "react";
import axios from "axios";
import { TRPC_SERVER_URI } from "../config/api";
import { getToken } from "../helpers/getToken";
import { useGymContext } from "./GymContext";
import { useProfile } from "./ProfileContext";


axios.defaults.baseURL = TRPC_SERVER_URI;

axios.interceptors.request.use(async (config) => {
  const token = await getToken();
  const url = config.url;
  if (token && url?.startsWith("/trpc")) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
      staleTime: 1000 * 60 * 2, // 2 minutes
    }
  }
});

export const TanstackQueryProvider = ({children}: PropsWithChildren) => {
  const {gymId} = useGymContext();

  const { clientProfileId } = useProfile();

  // Reset the query cache when the user changes gym or client profile
  useEffect(() => {
    queryClient.clear();
  }, [gymId, clientProfileId]);

  return <QueryClientProvider client={queryClient}>
    {children}
  </QueryClientProvider>
}