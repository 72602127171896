import React, {FC, useEffect, useMemo} from 'react';

import {useGymContext} from "../context/GymContext";
import {DynamicImportErrorBoundary} from "../components/DynamicImportErrorBoundary/DynamicImportErrorBoundary";
import Loading from "../components/Loading";
// import GymNavigator from "./Gym/GymNavigator";
import { PostHogProvider as PostHogProviderJS } from 'posthog-js/react';
import { PostHog } from '../helpers/PostHog';
import { useProfile } from '../context/ProfileContext';
const GymNavigator = React.lazy(() => import('./Gym/GymNavigator'));
const UserNavigator = React.lazy(() => import('./UserNavigator'));



const MainNavigator: FC = () => {
  
  const {gymToken, gymId} = useGymContext();
  const { clientProfileId } = useProfile();

  useEffect(() => {
    if (clientProfileId && gymId) {
      PostHog.init(clientProfileId, gymId);
    }
  }, [gymToken]);
  
  if (!gymToken) {
    return (
      <React.Suspense fallback={<div style={{minHeight: "100vh", minWidth: "100vw"}}><Loading backgroundColor={"purple"} /></div>}>
        {/* @ts-ignore */}
        <DynamicImportErrorBoundary>
          <PostHogProvider>
            <UserNavigator />
          </PostHogProvider>
        </DynamicImportErrorBoundary>
      </React.Suspense>
    )
  }
  
  return (
    <React.Suspense fallback={<div style={{minHeight: "100vh", minWidth: "100vw"}}><Loading backgroundColor={"pink"} /></div>}>
      {/* @ts-ignore */}
      <DynamicImportErrorBoundary>
        <PostHogProvider>
          <GymNavigator />
        </PostHogProvider>
      </DynamicImportErrorBoundary>
    </React.Suspense>
  )
};

const PostHogProvider = React.memo(({children}: {children: React.ReactNode}) => {

  const client = PostHog.init();

  return (
    <PostHogProviderJS client={client}>
      {children}
    </PostHogProviderJS>
  )
})


export default MainNavigator;
