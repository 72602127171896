import React from 'react';
import {gql, useQuery} from "@apollo/client";
import { generatePath, Link } from 'react-router-dom';
import cn from 'classnames';
export const CLIENT_NAME = gql`
  query ClientName($id: ID!){
    clientName(id: $id)
  }
`;
interface IOwnProps {
  id: string;
  firstName?: string;
  showLoading?: boolean;
  showLinkToProfile?: boolean;
  link?: string;
  className?: string;
}

export const ClientName = ({id, showLoading = true, firstName, showLinkToProfile = true, link, className}: IOwnProps) => {
  
  const { loading, data } = useClientName(id);

  const finalLink = link ? link : showLinkToProfile ? `/clients/view/${id}` : undefined;
  
  const content = (
    <span className={cn("masked", className)}>
      {!!firstName && !data?.clientName && firstName} {loading && showLoading ? "Loading" : data?.clientName ?? ""}
    </span>
  );

  if (finalLink) {
    return <Link to={finalLink} className="masked">{content}</Link>;
  }

  return content;
};

export const useClientName = (id: string) => useQuery<{clientName: string}>(CLIENT_NAME, {
  variables: {id},
  skip: !id,
});
