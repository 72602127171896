import { gql } from "@apollo/client";
import { WorkoutFragments } from "./fragments";

export const GYM_QUERY = gql`
  query Gym {
    gymLicense {
      id
      status
      expires
      limits {
        clients
        trainers
        gyms
      }
    }
    currentGym {
      id
      meta
      name
      status
      shortCode
      registrationCode
      selfRegistrationEnabled
      stripeId
      branding {
        id
        colors
        logoUrl
        logoOnDarkUrl
        fontFamily
        preferDarkMode
      }
      group {
        id
        name
        preferences
        gettingStartedStatus
        trainerColors
        stripeId
        useMasterPaymentAccount
        emailReplyAddress
        emailSendAddress
        stripeBillingEnabled
        notificationPreferences {
          id
          sessionReminderNotifications
          sessionReminderEmails
          minutesBeforeSession
          blackoutStartHour
          blackoutFinishHour
        }
        branding {
          id
          colors
          fontFamily
          logoUrl
          logoOnDarkUrl
          preferDarkMode
        }
      }
    }
    gymRoles {
      id
      roles
    }
  }
`;

export const GYM_GROUPS = gql`
  query GymGroups {
    gymGroups {
      id
      name
    }
  }
`;

export const VALIDATE_REGISTRATION_CODE = gql`
  query ValidateRegistrationCode(
    $gymCode: String!
    $registrationCode: String!
  ) {
    validateRegistrationCode(
      registrationCode: $registrationCode
      gymCode: $gymCode
    ) {
      success
      message
    }
  }
`;

export const managedGyms = gql`
  query ManagedGyms {
    managedGyms {
      id
      name
      timeZone
    }
  }
`;

export const authenticateWithGym = gql`
  mutation AuthenticateWithGym($gymId: ID!) {
    authenticateWithGym(gymId: $gymId) {
      token
    }
  }
`;

export const GYM_SESSION_TYPES = gql`
  query GymSessionTypes {
    gymSessionTypes {
      id
      name
      color
      status
    }
  }
`;

export const CLIENT_NOTES = gql`
  query ClientNotes($clientProfileId: String!) {
    clientNotes(where: { clientProfileId: $clientProfileId }) {
      id
      message
      pinned
      meta
      status
      createdAt
      updatedAt
      author {
        id
      }
    }
  }
`;

export const UNREGISTER_CLIENT = gql`
  mutation UnregisterClient(
    $clientProfileId: ID!
    $cancelSubscriptionsAtEndOfPeriod: Boolean
    $cancelSubscriptionsImmediately: Boolean
  ) {
    unregisterClient(
      clientProfileId: $clientProfileId
      cancelSubscriptionsAtEndOfPeriod: $cancelSubscriptionsAtEndOfPeriod
      cancelSubscriptionsImmediately: $cancelSubscriptionsImmediately
    ) {
      success
      message
    }
  }
`;

export const CLIENT_GROUPS = gql`
  query ClientGroups {
    gymClientGroups {
      id
      name
    }
  }
`;

export const CLIENT_GROUP = gql`
  query ClientGroup($id: ID!) {
    clientGroup(id: $id) {
      id
      name
      color
      createdAt
      updatedAt
      communityEnabled
      communityIdentifier
      clients {
        id
        firstName
      }
     
      lessonPlans {
        id
        name
      }
    }
  }
`;

export const GYM_CLIENTS = gql`
  query GymClients {
    gymClients {
      id
      firstName
      lastName
      imageUrl
      thumbnailUrl
      invitationSent
      trainerProfiles {
        id
      }
    }
  }
`;

export const gymPrices = gql`
  query GymPrices {
    gymPrices {
      id
      amount
      priceName
      productId
      recurrence
      currency
    }
    gymProducts {
      id
      productName
      description
    }
  }
`;

export const GYM_PRODUCTS = gql`
  query GymProducts {
    gymPrices {
      id
      amount
      priceName
      productId
      recurrence
      currency
    }
    gymProducts {
      id
      productName
      description
    }
  }
`;

export const GYM_SUBSCRIPTION_PRODUCTS = gql`
  query GymSubscriptionProducts {
    gymProducts(subscriptionsOnly: true) {
      id
      productName
      description
    }
  }
`;

export const GYM_ONE_OFF_PRODUCTS = gql`
  query GymOneOffProducts {
    gymProducts(productsOnly: true) {
      id
      productName
      description
      active
    }
    gymPrices {
      id
      amount
      priceName
      productId
      recurrence
      currency
      active
    }
    gymGroupProductSessionEntitlements {
      id
      productId
      productName
      limit
      weeklyLimit
      monthlyLimit
      sessionType {
        id
        name
        status
        oneOffPurchasePrice
        oneOffPurchaseCurrency
      }
    }
  }
`;

export const CHANGE_PRODUCT_STATUS = gql`
  mutation ChangeProductStatus($productId: String!, $active: Boolean!) {
    changeProductStatus(productId: $productId, active: $active) {
      id
      productName
      description
      active
    }
  }
`;

export const ProductPrices = gql`
  query ProductPrices($productId: String!, $includeInactive: Boolean) {
    gymPrices(productId: $productId, includeInactive: $includeInactive) {
      id
      amount
      priceName
      productId
      recurrence
      currency
      active
    }
  }
`;

export const StripeSubscription = gql`
  query StripeSubscription($subscriptionId: String!) {
    stripeSubscription(subscriptionId: $subscriptionId)
  }
`;

export const GYM_GROUP_LOG_SETTINGS = gql`
  query GymGroupLogSettings {
    gymGroupLogSettings
  }
`;

export const billingOverview = gql`
  query BillingOverview($since: DateTime!, $gymId: ID!) {
    badSubscriptions: stripeSubscriptions(
      where: {
        status_not_in: ["active", "canceled", "trialing"]
        paymentAccount: { gym: { id: $gymId } }
      }
      orderBy: updatedAt_DESC
    ) {
      ...SubscriptionDetails
    }

    cancelledSubscriptions: stripeSubscriptions(
      where: {
        OR: [{ canceledAt_gte: $since }, { endedAt_gte: $since }]
        paymentAccount: { gym: { id: $gymId } }
      }
      orderBy: updatedAt_DESC
    ) {
      ...SubscriptionDetails
    }

    pausedSubscriptions: stripeSubscriptions(
      where: { pauseBehaviour_gte: "", paymentAccount: { gym: { id: $gymId } } }
      orderBy: updatedAt_DESC
    ) {
      ...SubscriptionDetails
    }

    recentSubscriptions: stripeSubscriptions(
      where: {
        AND: { startDate_gte: $since, endedAt: null, canceledAt: null }
        paymentAccount: { gym: { id: $gymId } }
      }
      orderBy: updatedAt_DESC
    ) {
      ...SubscriptionDetails
    }

    gymPrices {
      id
      amount
      priceName
      productId
      recurrence
      currency
    }
    gymProducts {
      id
      productName
      description
    }
  }
  fragment SubscriptionDetails on StripeSubscription {
    id
    subscriptionId
    amount
    interval
    currency
    product
    collectionMethod

    status
    pauseBehaviour
    pauseResumes

    startDate
    endedAt
    canceledAt
    currentPeriodEnd

    paymentAccount {
      id
      user {
        id
        clientProfile {
          id
          firstName
          #          lastName
        }
      }
    }
  }
`;

// ! See gymPrices query, which includes products
// export const gymProducts = gql`
//   query GymProducts {
//     gymProducts{
//       id
//       productName
//       description
//     }
//   }
// `

export const cacheSubscriptions = gql`
  mutation CacheSubscriptions($force: Boolean, $stripeCustomerId: String) {
    cacheSubscriptions(force: $force, stripeCustomerId: $stripeCustomerId) {
      message
      success
    }
  }
`;

export const cacheClientSubscriptions = gql`
  mutation CacheClientSubscriptions($clientProfileId: ID!) {
    cacheClientSubscriptions(clientProfileId: $clientProfileId) {
      message
      success
    }
  }
`;

export const initiateTrainerRegistration = gql`
  mutation InitiateTrainerRegistration(
    $email: String!
    $firstName: String!
    $lastName: String!
  ) {
    initiateTrainerRegistration(
      email: $email
      firstName: $firstName
      lastName: $lastName
    ) {
      success
      message
    }
  }
`;

export const REMOVE_TRAINER_FROM_GYM = gql`
  mutation RemoveTrainerFromGym(
    $trainerProfileId: ID!
    $replacementTrainerProfileId: ID
    $removeFromAllGyms: Boolean
  ) {
    removeTrainerFromGym(
      trainerProfileId: $trainerProfileId
      replacementTrainerProfileId: $replacementTrainerProfileId
      removeFromAllGyms: $removeFromAllGyms
    ) {
      success
      message
    }
  }
`;

export const acceptTCsForGym = gql`
  mutation AcceptTCsForGym {
    acceptTCsForGym {
      id
    }
  }
`;

export const initiateGymRegistration = gql`
  mutation InitiateGymRegistration(
    $name: String!
    $managerEmail: String!
    $managerFirstName: String!
    $managerLastName: String!
    $gymGroupId: ID
    $gymGroupName: String
    $promoCode: String
  ) {
    initiateGymRegistration(
      name: $name
      managerEmail: $managerEmail
      managerFirstName: $managerFirstName
      managerLastName: $managerLastName
      gymGroupId: $gymGroupId
      gymGroupName: $gymGroupName
      promoCode: $promoCode
    ) {
      id
    }
  }
`;

export const SEND_INVITATION_EMAIL = gql`
  mutation SendInvitationEmail($clientProfileId: ID!) {
    sendInvitationEmail(clientProfileId: $clientProfileId) {
      success
      message
      password
      registrationLink
      email
    }
  }
`;

export const CREATE_CLIENT_NOTE = gql`
  mutation CreateClientNote($clientProfileId: String!, $message: String!) {
    createClientNote(clientProfileId: $clientProfileId, message: $message) {
      id
    }
  }
`;

export const DELETE_CLIENT_NOTE = gql`
  mutation DeleteClientNote($id: ID!) {
    deleteClientNote(id: $id){
      id
    }
  }
`
export const EDIT_CLIENT_NOTE = gql`
  mutation EditClientNote($id: ID!, $message: String, $pinned: Boolean) {
    editClientNote(id: $id, message: $message, pinned: $pinned){
      id
    }
  }
`

export const UPDATE_BRANDING = gql`
  mutation UpdateBranding($branding: BrandingCreateInput!) {
    updateBranding(branding: $branding) {
      id
      branding {
        id
        colors
        fontFamily
        preferDarkMode
      }
    }
  }
`;

export const UPDATE_GYM_GROUP_LOG_SETTINGS = gql`
  mutation UpdateGymGroupLogSettings($settings: GymGroupLogSettingsInput!) {
    updateGymGroupLogSettings(settings: $settings) {
      id
      dailyLogSettings
    }
  }
`;

export const UPDATE_GYM_GROUP_LOGO = gql`
  mutation UpdateGymGroupLogo($url: String, $type: String) {
    updateGymGroupLogo(url: $url, type: $type) {
      id
      branding {
        id
        colors
        fontFamily
        logoUrl
      }
    }
  }
`;

export const CREATE_SESSION_TYPE = gql`
  mutation CreateSessionType($name: String!, $color: String!) {
    createSessionType(name: $name, color: $color) {
      id
      name
    }
  }
`;

export const CREATE_CLIENT_GROUP = gql`
  mutation CreateClientGroup($name: String!, $color: String!) {
    createClientGroup(name: $name, color: $color) {
      id
      name
    }
  }
`;

export const DELETE_CLIENT_GROUP = gql`
  mutation DeleteClientGroup($id: ID!) {
    deleteClientGroup(id: $id) {
      id
    }
  }
`;

export const ADD_CLIENT_TO_GROUP = gql`
  mutation AddClientToGroup($clientId: ID!, $groupId: ID!) {
    addClientToGroup(clientId: $clientId, groupId: $groupId) {
      id
    }
  }
`;
export const ADD_CLIENTS_TO_GROUP = gql`
  mutation AddClientsToGroup($clientIds: [ID!]!, $groupId: ID!) {
    addClientsToGroup(clientIds: $clientIds, groupId: $groupId) {
      id
    }
  }
`;

export const REMOVE_CLIENT_FROM_GROUP = gql`
  mutation RemoveClientFromGroup($clientId: ID!, $groupId: ID!) {
    removeClientFromGroup(clientId: $clientId, groupId: $groupId) {
      id
    }
  }
`;

export const ASSIGN_WORKOUT_TO_CLIENT_GROUP = gql`
  mutation AssignWorkoutToClientGroup($workoutId: ID!, $clientGroupId: ID!) {
    assignWorkoutToClientGroup(
      workoutId: $workoutId
      clientGroupId: $clientGroupId
    ) {
      id
    }
  }
`;

export const UNASSIGN_WORKOUT_FROM_CLIENT_GROUP = gql`
  mutation UnassignWorkoutFromClientGroup(
    $workoutId: ID!
    $clientGroupId: ID!
  ) {
    unassignWorkoutFromClientGroup(
      workoutId: $workoutId
      clientGroupId: $clientGroupId
    ) {
      id
    }
  }
`;

export const TOGGLE_SELF_REGISTRATION = gql`
  mutation ToggleSelfRegistration($val: Boolean!) {
    toggleSelfRegistration(val: $val) {
      id
      selfRegistrationEnabled
      registrationCode
      shortCode
    }
  }
`;

export const UPDATE_GYM_GROUP_PREFERENCES = gql`
  mutation UpdateGymGroupPreferences($values: Json!) {
    updateGymGroupPreferences(values: $values) {
      id
      preferences
    }
  }
`;

export const UPDATE_GYM_GROUP_PREFERENCE = gql`
  mutation UpdateGymGroupPreference($key: String!, $value: Json!) {
    updateGymGroupPreference(key: $key, value: $value) {
      id
      preferences
    }
  }
`;

export const UPDATE_GYM_GROUP_CONTACT_DETAILS = gql`
  mutation UpdateGymGroupContactDetails(
    $emailReplyAddress: String
    $emailSendAddress: String
  ) {
    updateGymGroupContactDetails(
      emailReplyAddress: $emailReplyAddress
      emailSendAddress: $emailSendAddress
    ) {
      id
      emailReplyAddress
      emailSendAddress
    }
  }
`;

export const UPDATE_GYM_GROUP_NOTIFICATION_PREFERENCES = gql`
  mutation UpdateGymGroupNotificationPreferences(
    $data: NotificationPreferencesUpdateWithoutGymGroupDataInput
  ) {
    updateGymGroupNotificationPreferences(data: $data) {
      id
      notificationPreferences {
        id
        sessionReminderNotifications
        sessionReminderEmails
        minutesBeforeSession
        blackoutStartHour
        blackoutFinishHour
      }
    }
  }
`;

export const BanClient = gql`
  mutation BanClient(
    $clientProfileId: ID!
    $starts: DateTime
    $expires: DateTime
  ) {
    banClient(
      clientProfileId: $clientProfileId
      starts: $starts
      expires: $expires
    ) {
      id
    }
  }
`;

export const RemoveClientBan = gql`
  mutation RemoveClientBan($clientProfileId: ID!) {
    removeClientBan(clientProfileId: $clientProfileId) {
      id
    }
  }
`;

export const PauseStripeSubscription = gql`
  mutation PauseStripeSubscription(
    $subscriptionId: String!
    $until: DateTime!
  ) {
    pauseStripeSubscription(subscriptionId: $subscriptionId, until: $until)
  }
`;

export const ResumeStripeSubscription = gql`
  mutation ResumeStripeSubscription($subscriptionId: String!) {
    resumeStripeSubscription(subscriptionId: $subscriptionId)
  }
`;

export const CancelStripeSubscription = gql`
  mutation CancelStripeSubscription(
    $subscriptionId: String!
    $atEndOfPeriod: Boolean
    $removeCancellation: Boolean
    $cancelAt: DateTime
  ) {
    cancelStripeSubscription(
      subscriptionId: $subscriptionId
      atEndOfPeriod: $atEndOfPeriod
      removeCancellation: $removeCancellation
      cancelAt: $cancelAt
    )
  }
`;

export const UPDATE_GYM_GROUP_GETTING_STARTED_STATUS = gql`
  mutation UpdateGymGroupGettingStartedStatus($data: Json!) {
    updateGymGroupGettingStartedStatus(data: $data) {
      id
      gettingStartedStatus
    }
  }
`;

export const SyncStripeBillingEnabledStatus = gql`
  mutation SyncStripeBillingEnabledStatus {
    syncStripeBillingEnabledStatus
  }
`;
