import React from "react";
import {useHistory, useLocation} from "react-router-dom";
import ReactGA from "react-ga4";
import { PostHog } from "../helpers/PostHog";

const {VITE_GA_CODE} = import.meta.env;
ReactGA.initialize(VITE_GA_CODE!);


export const usePageTracking = () => {
  const {listen, location} = useHistory();
  
  // const location = useLocation();
  
  React.useEffect(() => {
    trackPageView(); // To track the first pageview upon load
    return listen(trackPageView); // To track the subsequent pageviews
  }, [location]);


  
  // React.useEffect(() => {
  //   console.log(document.title, {location});
  //
  //   ReactGA.send({
  //     hitType: "pageview",
  //     path: location.pathname,
  //     title: document.title,
  //   });
  // }, [location]);
  
  // React.useEffect(() => {
  //   console.log("Initialising GA")
  //   return listen(async location => {
  //     console.log(`Tracked page ${location.pathname}${location.search}`)
  //     try {
  //
  //       ReactGA.send({
  //         hitType: "pageview",
  //         path: "sample-integration",
  //         title: document.title,
  //       })
  //     } catch (e) {
  //       console.log("Error sending ga", e);
  //     }
  //     await window.localStorage.getItem("@user")
  //
  //   });
  // }, [listen]);
  
  
}


function trackPageView() {
  ReactGA.set({
    page: window.location.pathname
  });
  ReactGA.send("pageview");
  PostHog.trackEvent('$pageview', { '$current_url': window.location.href })

  // ga("set", "page", window.location.pathname);
  // ga("send", "pageview");
}
