let apiBase  = import.meta.env.VITE_API_BASE;
let wsBase  = import.meta.env.VITE_API_WS;
// let apiBase  = "https://fitliness-database-app.herokuapp.com"

// * Overrides for testing
// apiBase = "https://prisma.fitliness.co"
// wsBase = "wss://prisma.fitliness.co"
// if(process.env.NODE_ENV === "development" || import.meta.env.DEV) {
//   apiBase = "http://10.0.0.2:4000";
//   wsBase = "ws://10.0.0.2:4000";
//   console.log({apiBase});
// }


export const FUNCTIONS_BASE_URI = import.meta.env.VITE_FUNCTIONS_BASE_URI || `https://functions.vivi.fit`;

export const apiUri = apiBase;
export const wsUri = wsBase;


let trpcServerUri = "https://trpc.vivi.fit";
if(["local", "dev"].includes(import.meta.env.VITE_STAGE)){
  trpcServerUri = "http://localhost:3060";
}

export const TRPC_SERVER_URI = trpcServerUri;