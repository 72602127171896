import React, {PropsWithChildren} from "react";
import {WholeScreenLoading} from "../components/WholeScreenLoading/WholeScreenLoading";


interface ILoadingElement {
  id: string;
  loadingText?: string;
  timeout?: number;
  fadeInDuration?: number;
  displayDuration?: number;
}

interface IScreenLoadingContext {
  loadingElements: ILoadingElement[];
  removeElement(id: string): void;
  addElement(element: ILoadingElement): void
}

const noop = () => {
}


const ScreenLoadingContext = React.createContext<IScreenLoadingContext>({
  loadingElements: [],
  removeElement: noop,
  addElement: noop
});

const TOO_LONG_DURATION = 10000;


export const ScreenLoadingProvider: React.FC<PropsWithChildren> = ({children}) => {
  const [loadingElements, setLoadingElements] = React.useState<ILoadingElement[]>([]);
  const [takingTooLong, setTakingTooLong] = React.useState(false);
  const currentLoadingElements = React.useRef<ILoadingElement[]>([]);
  
  const addElement = (element: ILoadingElement) => {
    setLoadingElements(prev => {
      const nextLoadingElements = [...prev, element];
      currentLoadingElements.current = nextLoadingElements;
      return nextLoadingElements;
    });
    setTimeout(() => {
      if (currentLoadingElements.current.some(el => el.id === element.id)) {
        setTakingTooLong(true)
      }
    }, element.timeout ?? TOO_LONG_DURATION)
  }
  
  const removeElement = ((id: string) => {
    setLoadingElements(prev => {
      const nextLoadingElements = [...prev].filter(p => p.id !== id);
      currentLoadingElements.current = nextLoadingElements;
      if (!nextLoadingElements.length) {
        setTakingTooLong(false);
      }
      return nextLoadingElements;
    });
  });
  
  const onTooLongPress = () => {
    setLoadingElements([]);
    currentLoadingElements.current = [];
    setTakingTooLong(false);
  }
  
  return (
    <ScreenLoadingContext.Provider value={{removeElement, addElement, loadingElements}}>
      {/*<pre style={{fontSize: 12}}>{JSON.stringify({takingTooLong, loadingElements}, null, 2)}</pre>*/}
      <WholeScreenLoading
        displayDuration={loadingElements?.length ? loadingElements[0].displayDuration : undefined}
        fadeInDuration={loadingElements?.length ? loadingElements[0].fadeInDuration : undefined}
        loading={!!loadingElements.length} loadingText={loadingElements[0]?.loadingText || "Loading"}
        onTooLongPress={takingTooLong ? onTooLongPress : undefined}
      />
      {children}
    </ScreenLoadingContext.Provider>
  )
}

export const ScreenLoadingKeys = {
  SUBSCRIBE_TO_PLAN: "SUBSCRIBE_TO_PLAN",
}

export const useScreenLoading = () => React.useContext(ScreenLoadingContext);
export const useSimpleLoading = (val: boolean, loadingText?: string) => {
  const key = React.useMemo(() => `${Math.random() * 10_000_000}`, []);
  const {addElement, removeElement} = useScreenLoading();
  React.useEffect(() => {
    if (val) {
      addElement({id: key, loadingText})
    } else {
      removeElement(key);
    }
    
    return () => removeElement(key);
  }, [val])
}
